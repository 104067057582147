import Vue from 'vue';

import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

// import auth from "@/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/ekcsda",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
  },
  {
    path: "/management",
    name: "Management",
    // route level code-splitting
    // this generates a separate chunk (management.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "management" */ "../views/Management.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/dashboard-d",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    children: [
      {
        path: "/dashboard-d",
        name: "dashboard-d",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-d" */
            "../views/dashboardD.vue"
          ),
      },
      {
        path: "admin",
        name: "Admin",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Forms/Admin.vue"
          ),
      },
      {
        path: "administrative",
        name: "Administrative",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "administrative" */
            "@/components/Forms/Administrative.vue"
          ),
      },
      {
        path: "administrative",
        name: "express",
        props: (route) => ({ editExpress: route.params.editExpress, expressForm: route.params.expressForm, adminstrativesForm: route.params.adminstrativesForm }),
        component: () =>
          import(
            /* webpackChunkName: "administrative" */
            "@/components/Forms/Administrative.vue"
          ),
      },
      {
        path: "finance",
        name: "Finance",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "finance" */
            "@/components/Forms/Finance.vue"
          ),
      },
      {
        path: "cdp-information",
        name: "CdpInformation",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "cdpInformation" */
            "@/components/Forms/CDPInformation.vue"
          ),
      },
      {
        path: "community-information",
        name: "CommunityInfo",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "communityInfo" */
            "@/components/Forms/CommunityInfo.vue"
          ),
      },
      {
        path: "cpmc-information",
        name: "CPMCInformation",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "cmpcInformation" */
            "@/components/Forms/CPMCInformation.vue"
          ),
      },
      {
        path: "mps-information",
        name: "MpsInformation",
        props: (route) => ({ editUser: route.params.editUser }),
        component: () =>
          import(
            /* webpackChunkName: "mpsInformation" */
            "@/components/Forms/MpsInformation.vue"
          ),
      },
      {
        path: "admin-table",
        name: "AdminTable",
        component: () =>
          import(
            /* webpackChunkName: "adminTable" */
            "@/components/Tables/AdminTable.vue"
          ),
      },
      {
        path: "finance-table",
        name: "FinanceTable",
        component: () =>
          import(
            /* webpackChunkName: "financeTable" */
            "@/components/Tables/FinanceTable.vue"
          ),
      },

      {
        path: "administrative-table",
        name: "AdministrativeTable",
        component: () =>
          import(
            /* webpackChunkName: "administrativeTable" */
            "@/components/Tables/AdministrativeTable.vue"
          ),
      },
      {
        path: "information-reg-table",
        name: "CdpInformationTable",
        component: () =>
          import(
            /* webpackChunkName: "informationRegTable" */
            "@/components/Tables/CdpInformationTable.vue"
          ),
      },
      {
        path: "community-information-table",
        name: "CommunityInfoTable",
        component: () =>
          import(
            /* webpackChunkName: "communityInfoTable" */
            "@/components/Tables/CommunityInfoTable.vue"
          ),
      },
      {
        path: "cpmc-information-table",
        name: "CPMCInformationTable",
        component: () =>
          import(
            /* webpackChunkName: "cmpcInformationTable" */
            "@/components/Tables/CPMCInformationTable.vue"
          ),
      },
      {
        path: "mps-information-table",
        name: "MpsInformationTable",
        component: () =>
          import(
            /* webpackChunkName: "mpsInformationTable" */
            "@/components/Tables/MpsInformationTable.vue"
          ),
      },
      {
        path: "iva-table",
        name: "IvaTable",
        component: () =>
          import(
            /* webpackChunkName: "IvaTable" */
            "@/components/Tables/IvaTable.vue"
          ),
      },
      {
        path: "finance-record",
        name: "FinanceRecord",
        component: () =>
          import(
            /* webpackChunkName: "financeRecord" */
            "@/components/Tables/FinanceRecord.vue"
          ),
      },
    ],
  },
  {
    path: "/error 404",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ['/'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('curUser');

//   if (authRequired && !loggedIn) {
//     return next('/');
//   }

//   next();
// })
router.beforeEach((to, from, next) => {
  const loggedIn = true;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
