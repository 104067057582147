import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        secondary: "#E0E0E0",
        primary: "#FFCA28",
        accent: "#82B1FF",
        error: "#E53935",
        info: "#2196F3",
        success: "#33691E",
        warning: "#FFC107",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 400,
      sm: 680,
      md: 1024,
      lg: 1500,
    },
    scrollBarWidth: 24,
  },
});
