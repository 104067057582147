import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    // const userString = localStorage.getItem("curUser");
    // if (userString) {
    //   const payload = JSON.parse(userString);
    //   this.$store.commit("SET_CUR_USER", payload);
    // }
    const userForms = localStorage.getItem("forms");
    if (userForms) {
      const payload = JSON.parse(userForms);
      this.$store.commit("SET_FORMS", payload);
    }
    const userTables = localStorage.getItem("tables");
    if (userTables) {
      const payload = JSON.parse(userTables);
      this.$store.commit("SET_TABLES", payload);
    }
  },

  render: (h) => h(App),
}).$mount("#app");