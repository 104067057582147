<template>
  <div>
    <v-card>
      <v-img src="img/ekiti.jpg" contain> </v-img>
    </v-card>

    <v-container class="mt-6 text-justify">
      <v-row>
        <v-col cols="12" sm="6" md="5">
          <v-img src="img/logo.png" contain class="aboutimg mx-auto"></v-img>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <h2 class="success--text text-justify">About Us</h2>
          <p>
            Ekiti State Community and Social Development Agency (EKCSDA) is one
            of the formidable Community and Social Development Project (CSDP)
            family in Nigeria co-sponsor by world bank and Federal Government of
            Nigeria. EKCSDA became effective on April 1st, 2009, with a lifespan
            of 5 years which ended on 31st December 2013.
          </p>
          <p>
            Due to the superlative performances of the project in the first
            phase in Ekiti Stata and Nigeria at large, the project was extended
            by the World Bank till 31st December 2017 with additional financing
            of $140million. In Ekiti State, the intervention of CSDP has become
            a tool for Socio-Economic development at the grass-root level. Its
            areas of intervention include education, healthcare, rural water
            supply, rural electrification, environmental management and
            construction of social-economic development projects such as
            market-stalls, civic and skill Acquisition center...
            <v-btn :to="{ name: 'About' }" x-small color="primary"
              >Read More</v-btn
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
