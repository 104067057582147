<template>
  <v-app>
    <v-app-bar class="navbar" height="70" fixed app :clipped-left="clipped">
      <v-card height="60" width="60" rounded>
        <router-link :to="{ name: 'Home' }" class="text-decoration-none">
          <v-img src="/img/logo.png"></v-img
        ></router-link>
      </v-card>

      <router-link
        v-if="curUser"
        class="ml-2 text-decoration-none"
        :to="{ name: 'Dashboard' }"
        ><h4>Dashboard</h4></router-link
      >
      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn small :to="{ name: 'Home' }" class="mr-3">Home</v-btn>
        <v-btn small :to="{ name: 'About' }" class="mr-3">About</v-btn>
         <v-btn small :to="{ name: 'Projects' }" class="mr-3">Projects</v-btn>
        <v-btn small :to="{ name: 'Management' }" class="mr-3"
          >Management</v-btn
        >
        <v-btn
          v-if="curUser"
          small
          @click="logout"
          :loading="loading"
          class="mr-3"
          >Logout</v-btn
        >
      </div>
      <v-app-bar-nav-icon
        v-else
        @click="drawer = 'drawer'"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      app
      right
      hide-overlay
      temporary
      v-model="drawer"
      :drawer="!drawer"
    >
      <p class="text-end pa-3">
        <v-btn x-small text @click="hideDrawer"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </p>
      <v-list class="mt-6">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="{ name: item.link }"
        >
          <v-list-item-icon>
            <v-icon color="success">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="success--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 mb-10">
          <v-btn
            v-if="curUser"
            @click="logout"
            :loading="loading"
            block
            class="navbar"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer class="navbar" :clipped-left="clipped">
      <p class="mx-auto mt-5">
        Copyright &copy; {{ new Date().getFullYear() }} <span>EKCSDA.</span
        ><br />
      </p>
      <router-link
        v-if="!curUser"
        class="text-decoration-none"
        :to="{ name: 'Login' }"
      >
        <b class="text-end success--text">LOGIN</b></router-link
      >
    </v-footer>
    <v-snackbar
      v-model.trim="snackbar"
      :color="snackColor"
      bottom
      :timeout="5000"
    >
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { snackMixin } from "@/mixins";
import { mapState, mapActions } from "vuex";
export default {
  name: "App",
  mixins: [snackMixin],

  data() {
    return {
      drawer: false,
      clipped: true,
      items: [
        { title: "Home", icon: "mdi-home", link: "Home" },
        { title: "About", icon: "mdi-information", link: "About" },
         { title: "Projects", icon: "mdi-information", link: "Projects" },
        { title: "Management", icon: "mdi-account", link: "Management" },
      ],
    };
  },

  computed: {
    ...mapState(["login", "curUser"]),

    mobile() {
      return this.$vuetify.breakpoint.thresholds;
    },
  },

  watch: {
    mobile(newval) {
      if (!newval) {
        this.hideDrawer();
      }
    },
  },

  methods: {
    ...mapActions(["setLogin", "logout"]),

    hideDrawer() {
      this.drawer = false;
    },

    logout() {
      this.loading = true;

      setTimeout(() => {
        this.$store.dispatch("logout");
        this.setLogin(false);
        this.$router.push({ name: "Home" });
        this.loading = false;
      }, 3000);
    },
  },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
}
.navbar {
  background: linear-gradient(-90deg, rgb(204, 186, 22), rgb(9, 82, 25));
}

@media screen and (max-width: 600px) {
  .aboutimg {
    height: 100px;
    width: 100px;
  }
}

@media screen and (min-width: 601px) {
  .aboutimg {
    height: 200px;
    width: 200px;
  }
}
td,
th {
  border: 1.5px solid rgb(12, 12, 12);
}
</style>
