export const snackMixin = {
  data() {
    return {
      snackbar: false,
      snackMsg: "",
      snackColor: "",
      loading: false,
    };
  },

  methods: {
    displayMsg(msg, color = "success", loading = "loading", state = true) {
      this.snackMsg = msg;
      this.snackColor = color;
      this.snackbar = state;
      this[loading] = false;
    }, 
  },
};

export const editUserMixin = {
  computed: {
    editUser() {
      return this.$route.params.editUser;
    },
  },

  created() {
    // this.id = this.$route.params.editUser.id;
    this.editUser && (this.dform = this.editUser);
  },
};

export const editExpressMixin = {
  computed: {
    editExpress() {
      return this.$route.params.editExpress
    },

    hideAdministrative(){
      return this.$route.params.adminstrativesForm
    },
   showAdministrative(){
      return this.$route.params.expressForm
    }
  },

  created() {
    // this.id = this.$route.params.editUser.id;
    this.editExpress && (this.dform2 = this.editExpress);
    // this.expressionForm = this.$route.params.expressForm;
   !this.hideAdministrative && this.editExpress && (this.administrativeForm = this.hideAdministrative);
   this.showAdministrative && this.editExpress && (this.expressionForm = this.showAdministrative);
  },
};

export const editRoutesMixin = {
  computed: {
    editRoutes() {
      return this.$route.params.editRoutes;
    },
  },

  created() {
    this.editRoutes &&
      this.editRoutes.forEach((v) => {
        this.RouteSelected.push(v.route_id);
        this.oldRouteSelected.push(v.route_id);
      });
  },
};

export const rulesMixin = {
  data() {
    return {
      fieldRules: [(v) => !!v || "This Field is Required"],
    };
  },
};
