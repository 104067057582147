import Vue from "vue";
import Vuex from "vuex";
// import { apiClient } from "@/services";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: false,
    token: null,
    forms: [],
    tables: [],
    curUser: null,
    contributnPercent: {
      cdp: { agency: 97.5, community: 2.5 },
      grdp: { agency: 99, community: 1 },
    },
    admin: [],
    admins: ["Admin", "Super Admin"],
    meetings: [
      "Board Meeting",
      "Management Meeting",
      "Staff Meeting",
      "SSCU Meeting",
      "SSCC Meeting",
    ],
    projects: ["CDP", "GrDP"],
    status: ["Completed", "Ongoing", "Cancelled", "Not yet Funded"],
    sectors: ["Education", "Health", "Water and Sanitation", "Nutrition"],
    mps: ["CMP", "GMP"],
    committees: ["CPMC", "GPMC"],
    cdps: [],
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun-Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  mutations: {
    SET_ADMIN: (state, payload) => (state.admins = payload),

    SET_CUR_USER: (state, payload) => {
      // localStorage.setItem("curUser", JSON.stringify(payload));
      state.curUser = payload;
    },
    LOGOUT() {
      // localStorage.removeItem("curUser");
      localStorage.removeItem("forms");
      localStorage.removeItem("tables");
      localStorage.removeItem("cdps");
      // localStorage.removeItem("cdps_mps");
      location.assign('/');
    },

    SET_LOGIN: (state, payload) => (state.login = payload),
    SET_FORMS: (state, payload) => {
      localStorage.setItem("forms", JSON.stringify(payload));
      state.forms = payload;
    },
    SET_TABLES: (state, payload) => {
      localStorage.setItem("tables", JSON.stringify(payload));
      state.tables = payload;
    },
    SET_CDPS: (state, payload) => {
      localStorage.setItem("cdps", JSON.stringify(payload));
      state.cdps = payload;
    },
    // SET_CDPS_MPS: (state, payload) => {
    //   localStorage.setItem("cdps_mps", JSON.stringify(payload));
    //   state.cdps_mps = payload;
    // },
  },

  actions: {
    setLogin: ({ commit }, payload) => commit("SET_LOGIN", payload),
    setForms: ({ commit }, payload) => commit("SET_FORMS", payload),
    setTables: ({ commit }, payload) => commit("SET_TABLES", payload),
    setCurUser: ({ commit }, payload) => commit("SET_CUR_USER", payload),
    setAdmin: ({ commit }, payload) => commit("SET_ADMIN", payload),
    setCdps: ({ commit }, payload) => commit("SET_CDPS", payload),
    // setCdpsMps: ({ commit }, payload) => commit("SET_CDPS_MPS", payload),
    logout({ commit }) {
      commit("LOGOUT");
    },
  },

  getters: {
    userId: (state) => state.curUser.id || "",
    getAdminLevel: (state) => state.curUser.admin_level,
    getCurUser: (state) => state.curUser.Fullname,
    agencyContributn:
      (state) =>
      ({ projectType, projectCost }) => {
        let percentage =
          state.contributnPercent[projectType.toLowerCase()]["agency"];
        return Number(projectCost) * (percentage / 100);
      },
    communityContributn:
      (state) =>
      ({ projectType, projectCost }) => {
        let percentage =
          state.contributnPercent[projectType.toLowerCase()]["community"];
        return Number(projectCost) * (percentage / 100);
      },
    // isAdmin: (state) =>
    //   state.curUser.username
    //     ? state.curUser.username.toLowerCase().startsWith("admin")
    //     : false,
    // isAdmin: (state) => state.curUser.is_admin == 1
  },

  modules: {},
});